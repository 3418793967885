@import "src/assets/scss/variables";

.button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	padding: 22px 10px;
	min-width: 240px;
	border-radius: 40px;
	background-color: $green;
	color: $white;
	font-size: 16px;
	box-shadow: none;
	border: none;
	margin-inline-start: 0 !important;
	@media screen and (max-width: $mobile-size) {
		font-size: 14px;
		width: 100%;
		padding: 5px 20px;
	}
	&:disabled {
		background-color: $light-green;
	}

	&:hover {
		color: $white !important;
		transform: translateY(-5px);
		@media screen and (max-width: $mobile-size) {
			transform: translateY(0);
		}
	}

	&_icon {
		padding: 0;
		min-width: auto;
		width: auto !important;
		height: auto !important;
		border: none !important;
		background: transparent !important;
		&:hover {
			transform: translateY(0);
		}
	}

	&_circle {
		min-width: auto;
		width: 24px !important;
		height: 24px !important;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: transparent;
		@media screen and (max-width: $mobile-size) {
			width: 15px !important;
			height: 15px !important;
		}
	}
}

.button:global(.ant-btn-text) {
	padding: 0;
	background-color: transparent;
	color: $green;
	gap: 10px;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	min-width: auto;

	svg {
		circle {
			transition: stroke 0.3s ease-in;
		}

		path {
			transition: fill 0.3s ease-in;
		}
	}

	&:hover {
		transform: none;
		background-color: transparent;
		color: $dark-green !important;

		svg {
			circle {
				stroke: $dark-green;
			}

			path {
				fill: $dark-green;
			}
		}
	}

	&:disabled {
		transform: none !important;
		background-color: transparent !important;
		color: $disabled !important;

		svg {
			circle {
				stroke: $disabled;
			}

			path {
				fill: $disabled;
			}
		}
	}
}

.button:global(.ant-btn-ghost) {
	padding: 20px 40px;
	background-color: $light-green;
	color: $white;
	min-width: auto;
	@media screen and (max-width: $mobile-size) {
		padding: 5px 20px;
	}
}

.button:disabled {
	background-color: $border-grey !important;
	color: $white !important;
	cursor: not-allowed !important;

	&:hover {
		transform: none;
	}
}
