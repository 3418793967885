@import "src/assets/scss/_variables.scss";

.date {
	display: flex;
	gap: 10px;
	align-items: flex-end;
	width: 100%;
}

.datePickerWrapper {
	display: flex;
	flex-direction: column;
	@media screen and (max-width: $mobile-size) {
		width: 100%;
		flex-shrink: 0;
	}
}

.buttons {
	display: flex;
	gap: 10px;
	@media screen and (max-width: $mobile-size) {
		flex-direction: column;
		width: 100%;
	}
}
