@font-face {
	font-family: "GothamPro";
	src: url("../../assets/fonts/gothampro_light.eot");
	src: url("../../assets/fonts/gothampro_light.woff2") format("woff2"),
		url("../../assets/fonts/gothampro_light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "GothamPro";
	src: url("../../assets/fonts/gothampro.eot");
	src: url("../../assets/fonts/gothampro.woff2") format("woff2"),
		url("../../assets/fonts/gothampro.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "GothamPro";
	src: url("../../assets/fonts/gothampro_medium.eot");
	src: url("../../assets/fonts/gothampro_medium.woff2") format("woff2"),
		url("../../assets/fonts/gothampro_medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "GothamPro";
	src: url("../../assets/fonts/gothampro_bold.eot");
	src: url("../../assets/fonts/gothampro_bold.woff2") format("woff2"),
		url("../../assets/fonts/gothampro_bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "GothamPro";
	src: url("../../assets/fonts/gothampro_black.eot");
	src: url("../../assets/fonts/gothampro_black.woff2") format("woff2"),
		url("../../assets/fonts/gothampro_black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}
