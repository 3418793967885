@import "src/assets/scss/variables";

.wrapper {
	display: flex;
	align-items: center;
	gap: 10px;

	transition: opacity 0.25s ease-in;
	@media screen and (max-width: $mobile-size) {
		order: 1;
	}
	&_disabled {
		opacity: 0.3;

		* {
			cursor: not-allowed;
		}
	}
}

.spin {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $green;
	cursor: not-allowed;
}
