@import "src/assets/scss/variables";

.header {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin: 60px 0;
}

.date {
	display: flex;
	gap: 40px;
	align-items: flex-end;
}

.dateWrapper {
	display: flex;
	flex-direction: column;
	color: $dark-green;
}

.dateText {
	font-size: 20px;
	font-weight: 700;
}

.buttonsWrapper {
	display: flex;
	gap: 10px;
}

.tableStatus {
	color: $green;
	font-size: 16px;
}
