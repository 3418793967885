@import "src/assets/scss/variables";

.topBarInfo {
    display: flex;
	justify-content: space-between;
    gap: 30px;
    flex-grow: 1;

	&__alignTop {
		align-items: flex-start;
	}

	&__alignCenter {
		align-items: center;
	}

	&__alignEnd {
		justify-content: flex-end;
	}

    @media screen and (max-width: $mobile-size) {
		flex-direction: column;
        width: 100%;
        gap: 10px;
	}
}