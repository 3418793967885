@import 'src/assets/scss/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 60px 0;
}

.date {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  flex-grow: 1;

}

.datePickerWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.buttonsWrapper {
  display: flex;
  gap: 10px
}

.tableStatus {
  color: $green;
  font-size: 16px;
}