@import "src/assets/scss/variables";

.input {
	padding: 22px 30px;
	border-radius: 40px;
	font-size: 16px;
	color: $dark-green;
	border: 1px solid $border-grey;

	&:focus,
	&:hover {
        box-shadow: none;
		border-color: $dark-green;
	}

	&:-webkit-autofill {
		&,
		&:hover,
		&:focus {
			-webkit-box-shadow: 0 0 0px 1000px $light-grey inset;
		}
	}

	&::placeholder {
		color: $green;
	}

	&__center {
		text-align: center;
	}
}
