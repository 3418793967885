@import "src/assets/scss/variables";

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	@media screen and (max-width: $mobile-size) {
		width: calc(100vw - 40px);
		margin-top: 50px;
	}

	&__title {
		font-weight: 900 !important;
		color: $dark-green !important;
		text-transform: uppercase;
		margin: 0 0 60px !important;
		@media screen and (max-width: $mobile-size) {
			font-size: 34px !important;
		}
	}

	&__inputItem {
		width: 330px;
		margin-bottom: 20px;
		@media screen and (max-width: $mobile-size) {
			width: 100%;
		}
	}

	&__btn {
		text-transform: uppercase;
		min-width: 200px;
		margin-top: 10px;
		@media screen and (max-width: $mobile-size) {
			width: 200px;
			font-size: 16px;
			padding: 22px 0;
		}
	}

	&__errors {
		margin: 10px 0 20px;
		text-align: center;
		color: red;
	}
}
