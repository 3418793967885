@import "src/assets/scss/variables";

.layout {
	min-height: 100vh;
	height: 100%;
	width: 100vw;
	@media screen and (max-width: $mobile-size) {
		flex-direction: column !important;
	}
	&__main {
		@media screen and (max-width: $mobile-size) {
			width: 100% !important;
		}
	}
}

.sider {
	background: $light-grey !important;
	max-width: 250px !important;
	width: 100% !important;
	flex: 0 0 360px !important;
	padding: 60px 30px 60px 30px !important;
	@media screen and (max-width: $mobile-size) {
		flex: 0 0 auto !important;
		height: auto !important;
		padding: 40px 20px 40px 20px !important;
		max-width: 100% !important;
		position: absolute !important;
		left: -100%;
		top: 0;
		z-index: 5;
		transition: left 0.38s ease-in-out;
		&_active {
			left: 0;
		}
	}
	:global {
		.ant-layout-sider-children {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;
			@media screen and (max-width: $mobile-size) {
				flex-direction: row;
				align-items: center;
				height: auto;
				margin-top: 20px;
			}
		}
	}
}

.burger {
	display: none;
	position: absolute;
	width: 21px;
	height: 21px;
	z-index: 12;
	cursor: pointer;
	right: 20px;
	top: 20px;
	@media screen and (max-width: $mobile-size) {
		display: block;
	}
	span {
		background: #588157;
		border-radius: 3px;
		position: absolute;
		width: 100%;
		height: 2px;
		transition: 0.38s ease-in-out;
	}
	&:before,
	&:after {
		content: "";
		background: #588157;
		border-radius: 3px;
		position: absolute;
		width: 100%;
		height: 2px;
		left: 0;
		top: 7px;
		transition: 0.38s ease-in-out;
	}
	&:after {
		top: 14px;
	}
	&_open {
		&:before,
		&:after {
			transform: rotate(135deg);
			top: 10px;
		}
		span {
			transform: rotate(45deg);
			top: 10px;
		}
	}
}
.content {
	display: flex;
	flex-direction: column;
	padding: 60px 60px 50px;
	min-height: 280px;
	background-color: $white;
	@media screen and (max-width: $mobile-size) {
		padding: 20px 10px;
	}
}

.exit {
	background-color: transparent;
	border: none;
	padding: 0;
	box-shadow: none;
	font-size: 16px;
	color: $green-btn;

	&:hover {
		font-weight: 900;
		color: $dark-green !important;
	}
	@media screen and (max-width: $mobile-size) {
		align-self: flex-end;
	}
}
