$black: #000000;
$white: #ffffff;

$grey: #666666;
$border-grey: #DAD7CD;
$light-grey: #f0f2ec;
$scrollbar-grey: #fafafa;

$green: #588157;
$dark-green: #344e41;
$light-green: #a3b18a;
$green-btn: #3a5a40;

$disabled: #dcdcdc;

$mobile-size: 475px;

