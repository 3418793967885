@import "src/assets/scss/variables";

.menu {
	background: transparent;
	border: none;
	border-inline-end: none !important;

	:global {
		.ant-menu-item {
			font-size: 16px;
			color: $green-btn;
			white-space: normal;
			width: 100%;
			height: auto;
			padding: 0;
			margin: 0;
			line-height: 1;

			&:not(:last-child) {
				margin-bottom: 30px;
			}

			&:hover {
				font-weight: 900;
				color: $dark-green;
				background-color: transparent !important;
			}
		}

		.ant-menu-item-selected {
			font-size: 24px;
			font-weight: 900;
			background-color: transparent;
		}
	}
}
