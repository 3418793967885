.form {
	:global {
		.ant-form-item {
			margin-bottom: 0;
		}

		.ant-form-item-required::before {
			display: none !important;
		}
	}

	&__row {
		margin-bottom: 40px;
	}
}
